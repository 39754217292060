import React, { useContext } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../App";
const FlexContainer = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid lightgrey; */
    padding: 10px 20px;
    border-radius: 10px;
    margin: 10px 0;
    background-color:${(props) => props.theme.backSec};

`;
const RankContainer = Styled.div`
flex:0.5;
`;
const ToolName = Styled.div`
    /* styles for tool name */
    flex:3;
`;

const Rating = Styled.div`
    /* styles for rating */
    flex:2;
`;

const Status = Styled.div`
    /* styles for status */
    flex:1;
`;
const HeadingSpan = Styled.div`
font-weight:500;

font-size:18px;
color:${(props) => props.theme.fontPri};`;
const Span = Styled.span``;
const ToolContainer = () => {
  const { colorTheme } = useContext(ContextProvider);
  return (
    <FlexContainer theme={colorTheme}>
      <RankContainer>
        <HeadingSpan theme={colorTheme}>S.</HeadingSpan>
        {/* <Span>Tool Name</Span> */}
      </RankContainer>
      <ToolName>
        <HeadingSpan theme={colorTheme}>Tool Name</HeadingSpan>
        {/* <Span>Tool Name</Span> */}
      </ToolName>
      <Rating>
        <HeadingSpan theme={colorTheme}>Rating</HeadingSpan>
        {/* <Span>Rating</Span> */}
      </Rating>
      <Status>
        <HeadingSpan theme={colorTheme}>Status</HeadingSpan>
        {/* <Span>Status</Span> */}
      </Status>
    </FlexContainer>
  );
};

export default ToolContainer;
