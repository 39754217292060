import React, { useContext, useState } from "react";
import Navbar from "../Components/Navbar";
import Styled from "styled-components";
import { ContextProvider } from "../App";
import ToolContainer from "../Components/ToolContainer";
import ToolContainerData from "../Components/ToolContainerData";
import { Mobile } from "../response";
const Container = Styled.div``;

const Wrapper = Styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: calc(100vh - 50px);
  background-color: ${(props) => props.theme.backPri};
`;

const Left = Styled.div`
flex:5;
padding:20px 40px;
overflow:scroll;
height: calc(100vh - 50px);
box-sizing:border-box;
padding:20px 15%;
  ${Mobile({ padding: "0px 3%" })};
&::-webkit-scrollbar {
  display:none;
};`;

const TabSelector = Styled.div`
padding-top:5px;
padding-bottom:5px;
border-radius:10px;
display:flex;
align-items:center;
justify-content:center;
overflow:scroll;
&::-webkit-scrollbar {
  display:none;
};
${Mobile({ justifyContent: "flex-start" })};

`;

const Button = Styled.button`
font-family: 'rubik', sans-serif;
border:none;
background-color:transparent;
outline:none;
cursor:pointer;
font-size:16px;
padding:10px 20px;
border-radius:10px;
margin:0 10px;
transition:all 0.3s ease;
color:${(props) => props.theme.fontPri};
background-color:${(props) =>
  props.index === props.selectedDomain && props.theme.backSec};
&:hover{
  background-color:${(props) => props.theme.backSec};
};`;
const ComingSoonContainer = Styled.div`
color:${(props) => props.theme.fontPri};
display:flex;
align-items:center;
justify-content:center;
`;

const Span = Styled.span`
font-size:20px;`;
const Home = () => {
  const { colorTheme } = useContext(ContextProvider);
  const domains = ["Chat", "Code", "Photo", "Video", "PPT"];
  const type = ["popular", "benchmark"];
  const tools = {
    Chat: {
      benchmark: [
        {
          tool: "Chatgpt",
          rating: 1200,
          status: 1,
          link: "https://chat.openai.com/",
        },
        {
          tool: "Claude",
          rating: 1200,
          status: 1,
          link: "https://chaude.ai/chat",
        },
        {
          tool: "Bard",
          rating: 1200,
          status: 1,
        },
        {
          tool: "Meta",
          rating: 1200,
          status: 1,
        },
      ],
      popular: [
        {
          tool: "Chatgpt",
          rating: 1200,
          status: 0,
          link: "https://chat.openai.com/",
        },
        {
          tool: "Gemini",
          rating: 1190,
          status: 0,
          link: "https://gemini.google.com/",
        },
        {
          tool: "Bing AI",
          rating: 1180,
          status: 0,
          link: "https://www.bing.com/",
        },
        {
          tool: "Poe",
          rating: 1170,
          status: +3,
          link: "https://poe.com/",
        },
        {
          tool: "Perplexity AI",
          rating: 1160,
          status: -1,
          link: "https://perplexity.ai/",
        },
        {
          tool: "You",
          rating: 1150,
          status: +1,
          link: "https://you.com/",
        },
        {
          tool: "Claude",
          rating: 1140,
          status: +1,
          link: "https://claude.ai/onboarding",
        },

        {
          tool: "ChatPDF",
          rating: 1120,
          status: -2,
          link: "https://chatpdf.com/",
        },
      ],
    },
    Code: {
      benchmark: [
        {
          tool: "Devin AI",
          rating: 1200,
          status: 1,
        },
        {
          tool: "BlackBox",
          rating: 1200,
          status: 1,
        },
        {
          tool: "Phind",
          rating: 1200,
          status: 1,
        },
        {
          tool: "Copilot",
          rating: 1200,
          status: 1,
        },
        {
          tool: "Cloude3-Opus",
          rating: 1200,
          status: 1,
        },
      ],
      popular: [
        {
          tool: "Devin AI",
          rating: 1200,
          status: +8,
          link: "https://devin.ai",
        },
        {
          tool: "Claude",
          rating: 1190,
          status: +7,
          link: "https://claude.ai/onboarding",
        },
        {
          tool: "ChatGPT",
          rating: 1180,
          status: -2,
          link: "https://chat.openai.com/",
        },
        {
          tool: "Copilot",
          rating: 1170,
          status: 0,
          link: "https://copilot.github.com/",
        },
        {
          tool: "BlackBox",
          rating: 1160,
          status: 0,
          link: "https://blackbox.com/",
        },
        {
          tool: "Phind",
          rating: 1150,
          status: 0,
          link: "https://phind.com/",
        },
        {
          tool: "Gemini",
          rating: 1140,
          status: 0,
          link: "https://gemini.google.com/",
        },
        {
          tool: "Bing AI",
          rating: 1130,
          status: 0,
          link: "https://www.bing.com/",
        },
      ],
    },
    Photo: {
      popular: [
        {
          tool: "Character AI",
          rating: 1200,
          status: 3,
          link: "https://beta.character.ai/",
        },
        {
          tool: "Bing Create",
          rating: 1190,
          status: +7,
          link: "https://www.bing.com/",
        },
        {
          tool: "Midjourney",
          rating: 1180,
          status: -2,
          link: "https://midjourney.com/",
        },
        {
          tool: "PIXLR",
          rating: 1170,
          status: -2,
          link: "https://www.pixlr.com/",
        },
        {
          tool: "Photoroom",
          rating: 1160,
          status: -2,
          link: "https://photoroom.com/",
        },
        {
          tool: "Looka",
          rating: 1150,
          status: -2,
          link: "https://looka.com/",
        },
        {
          tool: "Playground AI",
          rating: 1140,
          status: -2,
          link: "https://playground.ai/",
        },
      ],
      benchmark: [
        {
          tool: "DreamStudio (Stable Diffusion)",
          rating: 1200,
          status: 1,
        },
        {
          tool: "Midjourney",
          rating: 1200,
          status: 1,
        },
        {
          tool: "DALL-E 3",
          rating: 1200,
          status: 1,
        },
        {
          tool: "Craiyon",
          rating: 1200,
          status: 1,
        },
      ],
    },
    Video: {
      benchmark: [
        {
          tool: "Sora",
          rating: 1200,
          status: 1,
          link: "https://chat.openai.com/",
        },
        {
          tool: "Synthesia",
          rating: 1200,
          status: 1,
        },
        {
          tool: "InVideo",
          rating: 1200,
          status: 1,
        },
        {
          tool: "Pictory",
          rating: 1200,
          status: 1,
        },
      ],
      popular: [
        {
          tool: "Sora",
          rating: 1200,
          status: +6,
          link: "https://chat.openai.com/",
        },
        {
          tool: "Synthesia",
          rating: 1190,
          status: -1,
          link: "https://www.synthesia.io/",
        },
        {
          tool: "InVideo",
          rating: 1180,
          status: -1,
          link: "https://invideo.io/",
        },
        {
          tool: "Pictory",
          rating: 1170,
          status: -1,
          link: "https://pictory.ai/",
        },
        {
          tool: "Opus Clip",
          rating: 1160,
          status: -1,
          link: "https://opusclip.com/",
        },
        {
          tool: "Vidyo AI",
          rating: 1150,
          status: -1,
          link: "https://vidyo.ai/",
        },
        {
          tool: "2short AI",
          rating: 1140,
          status: -1,
          link: "https://2short.ai/",
        },
        {
          tool: "Fliki",
          rating: 1130,
          status: -1,
          link: "https://fliki.ai/",
        },
      ],
    },
    PPT: {
      benchmark: [
        {
          tool: "Beautiful.ai",
          rating: 1200,
          status: 1,
        },
        {
          tool: "Simplified",
          rating: 1200,
          status: 1,
        },
        {
          tool: "Slidebean",
          rating: 1200,
          status: 1,
        },
        {
          tool: "Designs.ai",
          rating: 1200,
          status: 1,
        },
      ],
      popular: [
        {
          tool: "Plus AI",
          rating: 1200,
          status: 1,
          link: "https://plus.ai/",
        },
        {
          tool: "MagicSlides",
          rating: 1190,
          status: 1,
          link: "https://magicslides.app/",
        },
        {
          tool: "SlidesAI",
          rating: 1180,
          status: 1,
          link: "https://slidesai.io/",
        },
        {
          tool: "Gamma",
          rating: 1170,
          status: -3,
          link: "https://gamma.app/",
        },
        {
          tool: "Tome",
          rating: 1160,
          status: 0,
          link: "https://tome.com/",
        },
        {
          tool: "Simplified",
          rating: 1150,
          status: 0,
          link: "https://simplified.co/",
        },
      ],
    },
  };

  const [selectedDomain, setSelectedDomain] = useState(0);
  const [selectedType, setSelectedType] = useState(0);
  return (
    <Container>
      <Navbar />
      <Wrapper theme={colorTheme}>
        <Left>
          <TabSelector>
            {domains.map((item, index) => (
              <Button
                theme={colorTheme}
                onClick={() => setSelectedDomain(index)}
                index={index}
                selectedDomain={selectedDomain}
              >
                {item}
              </Button>
            ))}
          </TabSelector>
          <TabSelector>
            {type.map((item, index) => (
              <Button
                theme={colorTheme}
                onClick={() => setSelectedType(index)}
                index={index}
                selectedDomain={selectedType}
              >
                {item}
              </Button>
            ))}
          </TabSelector>
          <div>
            <ToolContainer></ToolContainer>
            {selectedType === 0 ? (
              tools[domains[selectedDomain]]["popular"].map((item, index) => (
                <ToolContainerData
                  rank={index + 1}
                  tool={item.tool}
                  rating={item.rating}
                  status={item.status}
                  link={item.link}
                  idx={domains[selectedDomain]}
                ></ToolContainerData>
              ))
            ) : (
              <ComingSoonContainer theme={colorTheme}>
                <Span>Coming Soon...</Span>
              </ComingSoonContainer>
            )}
          </div>
        </Left>
        {/* <Right>right</Right> */}
      </Wrapper>
    </Container>
  );
};

export default Home;
