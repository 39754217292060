import React, { createContext } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { useSelector } from "react-redux";
import Home from "./Wrapper/Home";
const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/home",
    element: <Navigate replace to="/" />,
  },
  {
    path: "/blog/:domain/:id",
    element: <Home />,
  },
]);
export const ContextProvider = createContext(null);
const App = () => {
  const theme = useSelector((state) => state.theme.currentTheme);
  const colorTheme =
    theme === "light"
      ? {
          backPri: "#e2e2e2",
          backSec: "#d1d1d1",
          fontPri: "#000000",
          fontSec: "grey",
          mainColor: "rgb(0, 129, 180)",
        }
      : {
          backPri: "#171717",
          backSec: "#212121",
          // backPri: "#282828",
          // backSec: "#1A1A1A",
          fontPri: "#FFFFFF",
          fontSec: "#F5F5F5",
          mainColor: "rgb(0, 129, 180)",
        };
  return (
    <ContextProvider.Provider
      value={{
        theme,
        colorTheme,
      }}
    >
      <RouterProvider router={router} />
    </ContextProvider.Provider>
  );
};

export default App;
