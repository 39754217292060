import React, { useContext } from "react";
import Navbar from "../Components/Navbar";
import Styled from "styled-components";
import { ContextProvider } from "../App";
import { Mobile } from "../response";
import {
  blogDataChat,
  blogDataCode,
  blogDataVideo,
  blogDataPicture,
  blogDataPPT,
} from "../data";
import { useLocation } from "react-router-dom";
const Container = Styled.div``;

const Wrapper = Styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: calc(100vh - 50px);
  background-color: ${(props) => props.theme.backPri};
  color: ${(props) => props.theme.fontPri};
`;

const Left = Styled.div`
  flex: 5;
  padding: 20px 40px;
  overflow: scroll;
  height: calc(100vh - 50px);
  box-sizing: border-box;
  &::-webkit-scrollbar {
    display: none;
  }
  ${Mobile({ padding: "20px 10px" })};
`;
const BlogWrapper = Styled.div`
  padding:0px 15%;
  ${Mobile({ padding: "0px 1%" })};
  
`;

const ImageContainer = Styled.div`
`;
const Image = Styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
`;

const Heading1Container = Styled.div`
padding: 25px 0;
`;

const Heading2Container = Styled.div`
padding: 20px 0;
`;

const Heading3Container = Styled.div`
text-align:justify;
`;

const Heading1 = Styled.span`
  font-size: 30px;
  font-weight: bold;
`;

const Heading2 = Styled.span`
font-size: 25px;
font-weight: bold;`;

const Paragraph = Styled.span`
font-size: 20px;
line-height: 1.5;`;

const SubImageContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
padding:10px 25%;
${Mobile({ padding: "10px 10px" })};`;

const Blog = () => {
  const { colorTheme } = useContext(ContextProvider);
  const location = useLocation();
  console.log(location.pathname.split("/")[3]);
  const path = location.pathname.split("/")[3];
  const domain = location.pathname.split("/")[2];
  const listArray = {
    chat: blogDataChat,
    code: blogDataCode,
    photo: blogDataPicture,
    video: blogDataVideo,
    ppt: blogDataPPT,
  };

  const [list, setList] = React.useState({});
  // console.log(path);
  // const BlogData = {
  //   heading: "ChatGpt",
  //   content: [
  //     {
  //       type: "image",
  //       content:
  //         "https://cdn-bdfbc.nitrocdn.com/mpQHUpeCagYMmyclYzWrEuJOvwEOajTJ/assets/images/optimized/rev-f99abd1/www.unimedia.tech/wp-content/uploads/2023/12/openAI-chat-gpt-1-4-1024x623.jpg",
  //     },
  //     {
  //       type: "subimage",
  //       content:
  //         "https://cdn-bdfbc.nitrocdn.com/mpQHUpeCagYMmyclYzWrEuJOvwEOajTJ/assets/images/optimized/rev-f99abd1/www.unimedia.tech/wp-content/uploads/2023/12/openAI-chat-gpt-1-4-1024x623.jpg",
  //     },
  //     {
  //       type: "heading1",
  //       content: "ChatGPT",
  //     },
  //     {
  //       type: "paragraph",
  //       content:
  //         "ChatGPT is a conversational model that can generate human-like responses to given prompts. It is based on the GPT-3 model, which is a state-of-the-art language model developed by OpenAI. ChatGPT is a smaller version of GPT-3 and is designed to be more efficient and cost-effective. It can be used for a variety of tasks, such as chatbots, customer support, and content generation.",
  //     },
  //     {
  //       type: "heading2",
  //       content: "How does ChatGPT work?",
  //     },
  //     {
  //       type: "paragraph",
  //       content:
  //         "ChatGPT works by using a large dataset of text to learn the patterns and structure of human language. It uses a technique called unsupervised learning, which means that it learns from the data without any explicit instructions. This allows it to generate human-like responses to given prompts, even if it has never seen those exact prompts before.",
  //     },
  //     {
  //       type: "heading2",
  //       content: "Applications of ChatGPT",
  //     },
  //     {
  //       type: "paragraph",
  //       content:
  //         "ChatGPT can be used for a variety of tasks, such as chatbots, customer support, and content generation. It can be used to generate human-like responses to customer queries, provide personalized recommendations, and create engaging content. It can also be used to automate repetitive tasks, such as answering frequently asked questions or scheduling appointments.",
  //     },
  //     {
  //       type: "heading2",
  //       content: "Limitations of ChatGPT",
  //     },
  //     {
  //       type: "paragraph",
  //       content:
  //         "While ChatGPT is a powerful tool, it is not without its limitations. It can sometimes generate responses that are irrelevant or nonsensical, and it may struggle with complex or ambiguous prompts. It also has the potential to generate biased or offensive content, especially if it is trained on biased or offensive data. It is important to use ChatGPT responsibly and to carefully monitor its output to ensure that it is appropriate and accurate.",
  //     },
  //     {
  //       type: "heading2",
  //       content: "Conclusion",
  //     },
  //     {
  //       type: "paragraph",
  //       content:
  //         "ChatGPT is a powerful tool that can be used for a variety of tasks, such as chatbots, customer support, and content generation. It is based on the GPT-3 model, which is a state-of-the-art language model developed by OpenAI. While ChatGPT has the potential to generate human-like responses to given prompts, it is not without its limitations. It is important to use ChatGPT responsibly and to carefully monitor its output to ensure that it is appropriate and accurate.",
  //     },
  //   ],
  //   link: "https://chat.openai.com/",
  // };
  const BlogData = listArray[domain].find((item) => item.heading === path);
  console.log(BlogData, domain);
  const renderContent = () => {
    return BlogData.content.map((item, index) => {
      if (item.type === "heading1") {
        return (
          <Heading1Container>
            <Heading1 key={index}>{item.content}</Heading1>
          </Heading1Container>
        );
      } else if (item.type === "heading2") {
        return (
          <Heading2Container>
            <Heading2 key={index}>{item.content}</Heading2>
          </Heading2Container>
        );
      } else if (item.type === "image") {
        return (
          <ImageContainer>
            <Image key={index} src={item.content} />
          </ImageContainer>
        );
      } else if (item.type === "subimage") {
        return (
          <SubImageContainer>
            <ImageContainer>
              <Image key={index} src={item.content} />
            </ImageContainer>
          </SubImageContainer>
        );
      } else if (item.type === "paragraph") {
        return (
          <Heading3Container>
            <Paragraph key={index}>{item.content}</Paragraph>
          </Heading3Container>
        );
      }
      return null;
    });
  };

  return (
    <Container>
      <Navbar />
      <Wrapper theme={colorTheme}>
        <Left>
          <BlogWrapper>{renderContent()}</BlogWrapper>
        </Left>
      </Wrapper>
    </Container>
  );
};

export default Blog;
