import React, { useContext } from "react";
import Styled from "styled-components";
import { ContextProvider } from "../App";
import { useNavigate } from "react-router-dom";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
const FlexContainer = Styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    cursor:pointer;
    border-bottom:${(props) => `1px solid lightgrey ${props.theme.backSec}`};
    padding: 10px 20px;
    margin: 10px 0;
    &:hover{
      background-color:${(props) => props.theme.backSec};
    };
   
`;
const RankContainer = Styled.div`
flex:0.5;
`;
const ToolName = Styled.div`
    /* styles for tool name */
    flex:3;
    display:flex;
    align-items:center;
`;

const Rating = Styled.div`
    /* styles for rating */
    flex:1;
`;

const Status = Styled.div`
    /* styles for status */
    display:flex;
    align-items:center;
    flex:1;
`;
const HeadingSpan = Styled.div`
font-size:17px;
color:${(props) => props.theme.fontPri};
`;

const ImageContainer = Styled.div`
padding:5px;`;
const TextContainer = Styled.div`
padding:5px;`;
const Img = Styled.img`
height:30px;
width:30px;
border-radius:10px;`;

const ToolContainer = ({ rank, tool, rating, status, link, idx }) => {
  const { colorTheme } = useContext(ContextProvider);
  const navigate = useNavigate();

  function convertString(inputString) {
    let lowercaseString = inputString.toLowerCase();
    let convertedString = lowercaseString.replace(/\s+/g, "-");
    navigate(`/blog/${idx.toLowerCase()}/${convertedString}`);
  }
  const ArrowUpStyle = {
    color: "green",
    fontSize: "30px",
    fontWeight: "bold",
  };
  const ArrowDownStyle = {
    color: "red",
    fontSize: "30px",
  };

  return (
    <FlexContainer onClick={() => convertString(tool)} theme={colorTheme}>
      <RankContainer>
        <HeadingSpan theme={colorTheme}>{rank}</HeadingSpan>
        {/* <Span>Tool Name</Span> */}
      </RankContainer>
      <ToolName>
        <ImageContainer>
          <Img
            src={`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${link}&size=48`}
            alt={"favicon"}
          />
        </ImageContainer>
        <TextContainer>
          <HeadingSpan theme={colorTheme}>{tool}</HeadingSpan>
        </TextContainer>
        {/* <Span>Tool Name</Span> */}
      </ToolName>
      <Rating>
        <HeadingSpan theme={colorTheme}>{rating}</HeadingSpan>
        {/* <Span>Rating</Span> */}
      </Rating>
      <Status>
        <ImageContainer>
          {status === 0 ? (
            <FiberManualRecordIcon style={ArrowUpStyle} />
          ) : status > 0 ? (
            <ArrowCircleUpIcon style={ArrowUpStyle} />
          ) : (
            <ArrowCircleDownIcon style={ArrowDownStyle} />
          )}

          {/* <ArrowCircleUpIcon style={ArrowUpStyle} />
          <ArrowCircleDownIcon style={ArrowDownStyle} />
          <FiberManualRecordIcon style={ArrowUpStyle} /> */}
        </ImageContainer>
        <TextContainer>
          {status !== 0 && (
            <HeadingSpan theme={colorTheme}>{status}</HeadingSpan>
          )}
        </TextContainer>

        {/* <Span>Status</Span> */}
      </Status>
    </FlexContainer>
  );
};

export default ToolContainer;
