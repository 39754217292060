import React, { useState, useContext } from "react";
import Styled from "styled-components";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import { toggleTheme } from "../redux/themeSlice";
import { useDispatch } from "react-redux";
import { ContextProvider } from "../App";
import { useNavigate } from "react-router-dom";
import TimelineIcon from "@mui/icons-material/Timeline";

const Container = Styled.div`
display:flex;
align-items:center;
justify-content:center;
height:50px;
padding:0 20px;
box-sizing:border-box;
background-color:${(props) => props.theme.backSec};

`;
const Wrapper = Styled.div`
margin-right:20px;
display:flex;
align-items:center;
justify-content:space-between;
flex:1;`;

const Left = Styled.div`
flex:3;`;

const Right = Styled.div`
display:flex;
align-items:center;
justify-content:flex-end;
flex:2;`;

const HeadingContainer = Styled.div`
display:flex;
`;
const IconContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
`;
const TextContainer = Styled.div`
display:flex;
align-items:center;
justify-content:center;
margin-left:10px;
`;
const Heading = Styled.span`
font-size:22px;
font-weight:500;
cursor:pointer;
color:${(props) => props.theme.fontPri};`;

const Navbar = () => {
  const toggle = useState(false);
  const dispatch = useDispatch();
  const toggleThemeCaller = () => {
    dispatch(toggleTheme());
  };
  const navigate = useNavigate();
  const { colorTheme, theme } = useContext(ContextProvider);
  const IconStyle = {
    height: "35px",
    width: "35px",
    color: theme === "dark" ? "white" : "#0066b2",
    cursor: "pointer",
  };
  const IconStyleTheme = {
    height: "30px",
    width: "30px",
    color: theme === "light" ? "black" : "white",
    cursor: "pointer",
  };
  return (
    <Container theme={colorTheme}>
      <Wrapper>
        <Left>
          <HeadingContainer>
            <IconContainer>
              <TimelineIcon style={IconStyle} />
            </IconContainer>
            <TextContainer>
              <Heading onClick={() => navigate("/")} theme={colorTheme}>
                AiLeaderboard
              </Heading>
            </TextContainer>
          </HeadingContainer>
        </Left>
        <Right>
          {toggle ? (
            <LightModeIcon
              onClick={() => toggleThemeCaller()}
              style={IconStyleTheme}
            />
          ) : (
            <DarkModeIcon
              onClick={() => toggleThemeCaller()}
              style={IconStyleTheme}
            />
          )}
        </Right>
      </Wrapper>
    </Container>
  );
};

export default Navbar;
