import React from "react";
import { useLocation } from "react-router-dom";
import HomePage from "../Pages/Home";
import Blog from "../Pages/Blog";
const Home = () => {
  const location = useLocation();
  const currentPath = location.pathname.split("/")[1];

  return (
    <div>
      {currentPath === "" && <HomePage />}
      {currentPath === "blog" && <Blog />}
    </div>
  );
};

export default Home;
